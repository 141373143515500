import React, { useEffect, useState, Component, useRef } from 'react';
import white_logo from '../images/white_logo.png';
import history from '../history';
import leftArrow from '../images/left_arrow.png'
import rightArrow from '../images/right-arrow.png'
import keyboard from '../images/keyboard.png'
import Slider from "react-slick";
import AuthService from '../services/auth';
import Modal from '../shared-component/modal';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import ArmyRobert from '../images/Goliath3.gif'
import AwareWolf from '../images/wolf.gif'
import CyberpunkGirl from '../images/trinity3.gif'
import DesertFox from '../images/MsSly.gif'
import FemNinja from '../images/Dominique3.gif'
import MrRobot from '../images/Quentin3.gif'

var Filter = require('bad-words'),
    filter = new Filter();

export default function Guest() {
  const [showMessage, setShowMessage] = useState("");
  const [alias, setAlias] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [btnText, setBtnText] = useState("");
  const [route, setRoute] = useState("");
  const [image, setImage] = useState("");
  const aliasInputEl = useRef(null);

  const venue = window.location.pathname.split("/")[2];
  const signinURL = `/dashboard/${venue}/signin`;
  const guestURL = `/dashboard/${venue}/guest`;
  const thankYouURL = `/dashboard/${venue}/thankyou?name=`;

  const focusAliasInput = () => {
    // Formik library fields/input wrappers don't extend
    // the ref prop. So we must target a parent element in useRef
    // then select the child input. 
      const inputEl = aliasInputEl?.current?.querySelector(".inputEl");
      if(inputEl) {
        inputEl.focus();
      }
  }
 
  useEffect(() => {
    const resetTimeInMinutes = 15;
    console.log('setting timer')
    // set up the original timer that will return to the start page after 5m
    let timer = setTimeout(() => {
        console.log('reset history')
        history.push(signinURL);
    }, resetTimeInMinutes * 60 * 1000)

    // When a click occurs, clear our existing timeout and re-create a new one
    const clickListener = () => {
        console.log('reset timer');
        clearTimeout(timer);
        timer = setTimeout(() => {
            console.log('reset history')
            history.push(signinURL);
        }, resetTimeInMinutes * 60 * 1000)
    };
    document.addEventListener('click', clickListener)
    // unsubscribe from the click listener and clear Timeout when this component unmounts
    return () => {
        document.removeEventListener('click', clickListener)
        clearTimeout(timer);
    }
}, [signinURL]);

useEffect(() => {
  async function fetch() {
    const response = await AuthService.getGuestAlias(venue);
    if(response.success) setAlias(response.data.alias);
  }
  fetch();
}, []);

class SimpleSlider extends Component {
    render() {
      const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        focusOnSelect: true,
        className: "center",
        centerMode: true,
        centerPadding: "30px",
        nextArrow: <CustomRight />,
        prevArrow: <CustomLeft />,
      };
      const { avatar } = this.props;

      return (
        <div className='slider' id="carousel">
          <Slider {...settings}>
            <div className="text-center">
                <div className={`d-flex flex-column label-box ${avatar === '1' ? `selected` : ''}`}>
                    <div className={`d-flex flex-column label`}>
                      <Field type="radio" name="avatar" value="1" checked/>
                    </div>
                    <img className="avatar" src={AwareWolf} alt='Avatar'/>
                </div>
            </div>
            <div className="text-center">
                <div className={`d-flex flex-column label-box ${avatar === '2' ? `selected` : ''}`}>
                    <div className={`d-flex flex-column label`}>
                      <Field type="radio" name="avatar" value="2" />
                    </div>
                    <img className="avatar" src={FemNinja} alt='Avatar'/>
                </div>
            </div>
            <div className="text-center">
                <div className={`d-flex flex-column label-box ${avatar === '3' ? `selected` : ''}`}>
                    <div className={`d-flex flex-column label`}>
                      <Field type="radio" name="avatar" value="3" />
                    </div>
                    <img className="avatar" src={CyberpunkGirl} alt='Avatar'/>
                </div>
            </div>
            <div className="text-center">
                <div className={`d-flex flex-column label-box ${avatar === '4' ? `selected` : ''}`}>
                    <div className={`d-flex flex-column label`}>
                      <Field type="radio" name="avatar" value="4" />
                    </div>
                    <img className="avatar" src={DesertFox} alt='Avatar'/>
                </div>
            </div>
            <div className="text-center">
                <div className={`d-flex flex-column label-box ${avatar === '5' ? `selected` : ''}`}>
                    <div className={`d-flex flex-column label`}>
                      <Field type="radio" name="avatar" value="5" />
                    </div>
                    <img className="avatar" src={MrRobot} alt='Avatar'/>
                </div>
            </div>
            <div className="text-center">
                <div className={`d-flex flex-column label-box ${avatar === '6' ? `selected` : ''}`}>
                    <div className={`d-flex flex-column label`}>
                      <Field type="radio" name="avatar" value="6" />
                    </div>
                    <img className="avatar" src={ArmyRobert} alt='Avatar'/>
                </div>
            </div>
          </Slider>
        </div>
      );
    }
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    const sliderCenterEl = document.querySelector(".slick-center");
    const selectedAvatar = sliderCenterEl.querySelector('input').value;
    setSubmitting(true);
    try {
        const alias = values.alias.trim();
        // validate alias length
        if (alias && (alias.length > 15 || alias.length < 3)) {
          setShowMessage("The Alias Should Be Between 3 or 15 Characters Long");
          setRoute("");
          setBtnText("TRY AGAIN");
          setImage("sad");
          setShowModal(true);
          return;
        }
        // validate alias characters
        const regex = /[^a-zA-Z0-9\s_@.-]/g;
        const regexFails = alias.match(regex); // array of offenders or null(null === valid alias)
        if(regexFails){
          const regexOffenders = regexFails.join(' ');
          setShowMessage(`The Alias contains forbidden special characters: "${regexOffenders}"`);
          setRoute("");
          setBtnText("TRY AGAIN");
          setImage("sad");
          setShowModal(true);
          return;          
        }
        // validate alias cleanliness(profanity)
        const aliasFilter = alias !== "" ? filter.clean(alias) : null;
        if (aliasFilter !== null && aliasFilter.includes('**')) {
            setShowMessage("Please enter a valid Alias");
            setRoute("");
            setBtnText("TRY AGAIN");
            setImage("sad");
            setShowModal(true);
            return;
        }
        const avatar_name = selectedAvatar;
        const status = true;
        if (!avatar_name) {
            document.getElementById("submit-btn").focus();
            setShowMessage("Please select an avatar");
            setRoute("");
            setBtnText("TRY AGAIN");
            setImage("sad");
            setShowModal(true);
            return;
        }
        // disable formSubmitBtn to prevent duplicate registrations.
        const formSubmitBtn = document.getElementById("submit-btn");
        formSubmitBtn.classList.add('disable');
        formSubmitBtn.disabled = true;
        let response = await AuthService.register(
          alias, avatar_name, venue, status
        );
        if (!response.success) {
          // if fails, enable the submitbutton
          formSubmitBtn.disabled = false;
          formSubmitBtn.classList.remove('disable');
          let message = response.data.message;
          // if (token === 'null') {
          //     message = `This username is already taken or you have already registered for the`
          // }
          setShowMessage(message);
        if (response.data?.reason === 'Alias'){
          setRoute('');
        } else {
          setRoute(guestURL);
        }
          setBtnText("Close");
          setImage("sad");
          setShowModal(true);
          return;
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("token");
          history.push(thankYouURL + alias);
        }

    } catch (err) {
        console.error(err);
        setShowMessage('Registration failed, please try again.');
        setRoute(guestURL); //reloads page
        setBtnText("TRY AGAIN");
        setImage("sad");
        setShowModal(true);
    }

    setSubmitting(false);
  }

  const CustomRight = ({ onClick }) => (
    <div className="arrow right-arrow" onClick={onClick}>
      <img src={rightArrow} alt='right arrow'/>
    </div>
  );

  const CustomLeft = ({ onClick }) => (
    <div className="arrow left left-arrow" onClick={onClick}>
      <img src={leftArrow} alt='left arrow'/>
    </div>
  );

  return (
    <div className="main" id="guest">
      {showModal && <Modal btnText={btnText} image={image} route={route} message={showMessage} setShowModal={setShowModal} />}
      <div className="header">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <div className="logo-div">
              <img src={white_logo} alt="Limitless VR Logo" className="cursor" onClick={() => history.push(signinURL)}/>
            </div>
          </div>
        </div>
  
        <div className="container">
          <div className="row">
            <div className="col-md-12 center-text text-uppercase">
              <h1 className="bold">Select an avatar and alias</h1>
            </div>
          </div>

            <Formik
              enableReinitialize={true}
              initialValues={{
                  alias: alias,
                  avatar: '1'
              }}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, values }) => (

                  <Form action="" id="RegisterForm">
                      <div className="row">
                        <div className="col-md-10 offset-md-1">
                            <div className="right">
                                <div className="row">
                                            {/* { showMessage !== "" ? <span style={{ color: "red" }}>{" * " + showMessage}</span> : null } */}
                                    <div className=" input-div col-md-12 slider-div">
                                        <SimpleSlider avatar={values.avatar}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-9 offset-md-1 checkalias">
                            {/* {guest === "true" ? */}
                            <div className="input-div">
                                <div className="f-name alias" id="field-text">
                                  <label htmlFor='fname' className="queue-time text-uppercase bold">Player Alias</label>
                                  {errors.username && touched.username ? (
                                      <span style={{ color: "red" }}>{" * " + errors.username}</span>
                                  ) : null}
                                </div>
                                <div ref={aliasInputEl}>
                                  <img src={keyboard} alt='keyboard' className='keyboard-icon' onClick={focusAliasInput}/>
                                    <Field
                                        type="text"
                                        name="alias"
                                        className="inputEl"
                                    // disabled={token == 'null' ? false : true}
                                    />
                                </div>
                            </div>
                        </div>
                      </div>

                      <div className="center">
                          <button type='submit' id="submit-btn">Check in</button>
                          </div>
                      <div className="center">
                        <div className="restart cursor" onClick={() => {
                                setShowMessage('Are you sure you want to go back?');
                                setRoute(signinURL);
                                setBtnText("yesno");
                                setImage("noimage");
                                setShowModal(true);
                        }}>
                          <h3><img src={leftArrow} alt='arrow'/>Restart</h3>
                        </div>
                      </div>
                  </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
  );
}