import React, { useEffect, useState, Component } from 'react';
import Modal from '../shared-component/modal';
import logo from '../images/logo.png';
import leftArrow from '../images/left_arrow.png'
import rightArrow from '../images/right-arrow.png'
import ArmyRobert from '../images/ArmyRobertFront_min3.gif'
import AwareWolf from '../images/AwareWolfFront_min3.gif'
import CyberpunkGirl from '../images/CyberpunkGirlFront_min3.gif'
import DesertFox from '../images/DesertFoxFront_min3.gif'
import FemNinja from '../images/FemNinjaFront_min3.gif'
import MrRobot from '../images/MrRobotFront_min3.gif'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import AuthService from '../services/auth';
import history from '../history';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

var Filter = require('bad-words'),
    filter = new Filter();

export default function Registeration() {

    const [showModal, setShowModal] = useState(false);
    const [showMessage, setShowMessage] = useState("");
    const [route, setRoute] = useState("");
    const [btnText, setBtnText] = useState("");
    const [image, setImage] = useState("");
    const [queueName, setQueue] = useState("");

    const [username, setUsername] = useState(localStorage.getItem("username") || '');
    const [firstname, setFirstName] = useState(localStorage.getItem("firstname") || '');
    // const [guest, setGuest] = useState(localStorage.getItem("guest") || '');
    // const [token, setToken] = useState(localStorage.getItem("token") || '');
    const [slotsList, setSlotsList] = useState([]);
    // const [selectedItem, setSelectedItem] = useState('');
    const [selectedTimeSlot, setSelectedTimeSlot] = useState("");
    // const [headsets, getHeadSets] = useState([]);
    const [selectedHeadSet, setSelectedHeadSets] = useState("");
    const [viewableTimeSlots, setViewableTimeSlots] = useState([]);
    // const [viewableHeadSets, setViewableHeadSets] = useState([]);
    const [venue_data, setVenue] = useState("")
    const venue = window.location.pathname.split("/")[2];
    const loginurl = `/dashboard/${venue}/login`
    const landingUrl = `/dashboard/${venue}/landing`
    const startUrl = `/dashboard/${venue}/start`
    const registerUrl = `/dashboard/${venue}/registration`
    // const [defaultHeadSet, setDefaultHeadSets] = useState([]);
    // const [errorMessage, setErrorMessage] = useState("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        let venue_res = await AuthService.getVenues();

        const queue_name = localStorage.getItem("selectedSuite")
        const h_n = localStorage.getItem("selectedHeadSet")
        async function fetchData() {
            let response = await AuthService.getAvailableSlots();
            setSlotsList(response);
            setViewableTimeSlots(response);
            setSelectedHeadSet(h_n)
            selectTimeSlot(queue_name)
        }
        fetchData();
        venue_res.includes(venue) ? setVenue(venue) : setVenue("")
    }, []);
    
    // Set up timer to go back to start page if user idles on this page
    useEffect(() => {
        const resetTimeInMinutes = 5;
        console.log('setting timer')
        // set up the original timer that will return to the start page after 5m
        let timer = setTimeout(() => {
            console.log('reset history')
            history.push(startUrl);
        }, resetTimeInMinutes * 60 * 1000)

        // When a click occurs, clear our existing timeout and re-create a new one
        const clickListener = () => {
            console.log('reset timer');
            clearTimeout(timer);
            timer = setTimeout(() => {
                console.log('reset history')
                history.push(startUrl);
            }, resetTimeInMinutes * 60 * 1000)
        };
        document.addEventListener('click', clickListener)
        // unsubscribe from the click listener and clear Timeout when this component unmounts
        return () => {
            document.removeEventListener('click', clickListener)
            clearTimeout(timer);
        }
    }, []);
    class SimpleSlider extends Component {
        render() {
          const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 2,
            nextArrow: <CustomRight />,
            prevArrow: <CustomLeft />
          };
          const { avatar } = this.props;

          return (
            <div className='slider' id="carousel">
              <Slider {...settings}>
                <div className="text-center">
                    <div className={`d-flex flex-column label-box ${avatar === '1' ? `selected-${selectedTimeSlot}` : ''}`}>
                        <div className={`d-flex flex-column label`}>
                            <label className='bold'>
                                <Field type="radio" name="avatar" value="1" />
                                Atlas
                            </label>
                        </div>
                        <img className="avatar" src={AwareWolf} />
                    </div>
                </div>
                <div className="text-center">
                    <div className={`d-flex flex-column label-box ${avatar === '2' ? `selected-${selectedTimeSlot}` : ''}`}>
                        <div className={`d-flex flex-column label`}>
                            <label className='bold'>
                                <Field type="radio" name="avatar" value="2" />
                                Trinity
                            </label>
                        </div>
                        <img className="avatar" src={FemNinja} />
                    </div>
                </div>
                <div className="text-center">
                    <div className={`d-flex flex-column label-box ${avatar === '3' ? `selected-${selectedTimeSlot}` : ''}`}>
                        <div className={`d-flex flex-column label`}>
                            <label className='bold'>
                                <Field type="radio" name="avatar" value="3" />
                                Dominique
                            </label>
                        </div>
                        <img className="avatar" src={CyberpunkGirl} />
                    </div>
                </div>
                <div className="text-center">
                    <div className={`d-flex flex-column label-box ${avatar === '4' ? `selected-${selectedTimeSlot}` : ''}`}>
                        <div className={`d-flex flex-column label`}>
                            <label className='bold'>
                                <Field type="radio" name="avatar" value="4" />
                                Ms. Sly
                            </label>
                        </div>
                        <img className="avatar" src={DesertFox} />
                    </div>
                </div>
                <div className="text-center">
                    <div className={`d-flex flex-column label-box ${avatar === '5' ? `selected-${selectedTimeSlot}` : ''}`}>
                        <div className={`d-flex flex-column label`}>
                            <label className='bold'>
                                <Field type="radio" name="avatar" value="5" />
                                Quentin
                            </label>
                        </div>
                        <img className="avatar" src={MrRobot} />
                    </div>
                </div>
                <div className="text-center">
                    <div className={`d-flex flex-column label-box ${avatar === '6' ? `selected-${selectedTimeSlot}` : ''}`}>
                        <div className={`d-flex flex-column label`}>
                            <label className='bold'>
                                <Field type="radio" name="avatar" value="6" />
                                Goliath
                            </label>
                        </div>
                        <img className="avatar" src={ArmyRobert} />
                    </div>
                </div>
              </Slider>
            </div>
          );
        }
      }


    const getValidationSchema = () => {
      return Yup.object().shape({
          firstname: Yup.string()
              .min(1, 'Too Short!')
              .max(30, 'Too Long!')
              .required('Please enter First Name'),

          username: Yup.string()
              .min(2, 'Too Short!')
              .max(30, 'Too Long!')
              .required('Please enter a valid Alias'),

    });
  }

    const handleSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
            const alias = values.username === "" ? values.firstname : values.username;
            const firstname = values.firstname
            // const filterFirstName = firstname !== "" ? filter.clean(firstname) : null;
            const aliasFilter = alias !== "" ? filter.clean(alias) : null;
            if (alias !== "" && alias.length > 15) {
                setShowMessage("The Alias Should Be Less Than 15 Characters");
                setRoute("");
                setBtnText("TRY AGAIN");
                setImage("sad");
                setShowModal(true);
                return;
            }
            if (aliasFilter !== null && aliasFilter.includes('**')) {
                setShowMessage("Please enter a valid Alias");
                setRoute("");
                setBtnText("TRY AGAIN");
                setImage("sad");
                setShowModal(true);
                return;
            }
            const queue = localStorage.getItem("selectedSuite");
            const email = localStorage.getItem("email");;
            const avatar_name = values.avatar;
            const headset_number = localStorage.getItem("selectedHeadSet");
            const status = true;
            if (!firstname) {
                setShowMessage("Please enter your first name");
                setRoute("");
                setBtnText("TRY AGAIN");
                setImage("sad");
                setShowModal(true);
                return;
            }
            if (!values.username) {
              setShowMessage("Please enter a valid Alias");
              setRoute("");
              setBtnText("TRY AGAIN");
              setImage("sad");
              setShowModal(true);
              return;
            }
            if (!avatar_name) {
                document.getElementById("submit-btn").focus();
                setShowMessage("Please select an avatar");
                setRoute("");
                setBtnText("TRY AGAIN");
                setImage("sad");
                setShowModal(true);
                return;
            }
            // disable formSubmitBtn to prevent duplicate registrations.
            const formSubmitBtn = document.getElementById("submit-btn");
            formSubmitBtn.classList.add('disable');
            formSubmitBtn.disabled = true;
            let response = await AuthService.register(
                firstname, alias, email, avatar_name, venue, queue, headset_number, status
                );
                if (!response.success) {
                // if fails, enable the submitbutton
                formSubmitBtn.disabled = false;
                formSubmitBtn.classList.remove('disable');
                let message = response.data.message;
                // if (token === 'null') {
                //     message = `This username is already taken or you have already registered for the`
                // }
                setShowMessage(message);
                if (response.data?.reason === 'Alias'){
                    setRoute('');
                } else {
                    setRoute(landingUrl);
                }
                setBtnText("Close");
                setImage("sad");
                setShowModal(true);
                setQueue(queue.replace(/^./, str => str.toUpperCase()))
                return;
            } else {
                localStorage.removeItem("username");
                localStorage.removeItem("email");
                localStorage.removeItem("token");
                localStorage.removeItem("firstname");
                history.push(startUrl)
            }

        } catch (err) {
            setShowMessage('Registration failed, please try again.');
            setRoute(registerUrl); //reloads page
            setBtnText("TRY AGAIN");
            setImage("sad");
            setShowModal(true);
        }

        setSubmitting(false);
    }

    const setSelectedHeadSet = (data) => {
        setSelectedHeadSets(data.headset_number);
    }

    const selectTimeSlot = (data) => {
        
        setSelectedTimeSlot(data);
        
    };

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 4,
            slidesToSlide: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1,
        },
    };

    const CustomRight = ({ onClick }) => (
        <div className="arrow right-arrow" onClick={onClick}>
            <img src={rightArrow} />
        </div>
    );

    const CustomLeft = ({ onClick }) => (
        <div className="arrow left left-arrow" onClick={onClick}>
            <img src={leftArrow} />
        </div>
    );

    return (
        <div className="main">
            {showModal && <Modal btnText={btnText} image={image} route={route} message={showMessage} setShowModal={setShowModal} queueName={queueName} />}
            <div className="header">
                <div className="row">
                    <div className="col-md-4 offset-md-4">
                        <div className="logo-div" onClick={ () => history.push(startUrl) }>
                            <img src={logo} alt="Logo" className='cursor'/>
                        </div>
                    </div>
                </div>

                <div className="container max-width-1200" id="registration">
                    <div className="row">
                        <div className="col-md-12 center-text text-uppercase">
                            <h1 className="bold black">Sign-in</h1>
                        </div>
                        <div className="line"></div>
                        <div className="col-md-10 offset-md-1">

                                <Formik
                                    enableReinitialize={true}
                                    initialValues={{
                                        username: username,
                                        alias: '',
                                        firstname: firstname,
                                        picked: ''
                                    }}
                                    validationSchema={getValidationSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({ errors, touched, handleChange, values, setFieldValue }) => (

                                        <Form action="" id="RegisterForm">
                                            <div className="row">
                                                <div className="col-lg-10 offset-md-1 sign-in-fields" id={`${selectedTimeSlot}-border`}>
                                                    {/* {console.log("guest", guest)} */}
                                                    {/* {guest === "true" ? */}
                                                    <div className="input-div">
                                                        <div className="d-flex">
                                                            <div onChange={handleChange}className="f-name" id="field-text">
                                                                <span className="queue-time text-uppercase bold">First Name</span>
                                                                {errors.firstname && touched.firstname ? (
                                                                    <span style={{ color: "red" }}>{" * " + errors.firstname}</span>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <Field
                                                                type="text"
                                                                name="firstname"
                                                                className="inputEl"
                                                            // disabled={token == 'null' ? false : true}
                                                            />
                                                        </div>
                                                    </div>
                                                    {/*  : null} */}
                                                    <div className="input-div">
                                                        <div className="d-flex">
                                                            <div className="f-name" id="field-text">
                                                                <span className="queue-time text-uppercase bold">Alias</span>
                                                                {errors.username && touched.username ? (
                                                                    <span style={{ color: "red" }}>{" * " + errors.username}</span>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <Field
                                                                type="text"
                                                                name="username"
                                                                className="inputEl"
                                                            // disabled={token == 'null' ? false : true}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="col-md-11">
                                                <div className="right">
                                                    <div className="row" style={{ marginLeft: '0px' }}>
                                                                {/* { showMessage !== "" ? <span style={{ color: "red" }}>{" * " + showMessage}</span> : null } */}
                                                        <div className=" input-div col-lg-12 col-md-12 col-sm-12 slider-div">
                                                            <SimpleSlider avatar={values.avatar} />

                                                            {/* <div>Picked: {values.avatar}</div> */}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="line"></div>
                                            <div className="center">
                                                <button
                                                    type="submit"
                                                    id="submit-btn"
                                                    // disabled={true}//{selectedTimeSlot && selectedItem ? false : true} 
                                                    className="bg-card cursor sign-in"//{selectedTimeSlot && selectedItem ? "signup-btn" : "btn-disable"}
                                                >
                                                    <div className="action">
                                                        <h2 className="queue-name text-uppercase">Sign-in</h2>
                                                    </div>
                                                </button>

                                                <div className="restart cursor" onClick={() => {
                                                        setShowMessage('Are you sure you want to go back?');
                                                        setRoute(landingUrl);
                                                        setBtnText("yesno");
                                                        setImage("noimage");
                                                        setShowModal(true);
                                                        setQueue("")
                                                }}>
                                                    <h3><img src={leftArrow} />Restart</h3>
                                                </div>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
